<template>
  <div v-if="isAdmin || checkAccess('statements')">
    <report-list-page
        page-title="Statement"
        page-icon="mdi-cash-multiple"
        :descending="true"
        :headers="headers"
        state-end-point="statement.statements"
        :actions="actions"
        :show-component="showComponent"
        :show-grand-total="false"
        item-key="id"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "StatementReport",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: 'Date',
          value: 'orderDate',
          sortable: false
        },
        {
          text: 'No. of Invoice',
          value: 'totalInvoice',
          sortable: false
        },
        {
          text: 'Total Amount',
          value: 'totalAmount',
          sortable: false
        },
        {
          text: 'No. of Unpaid',
          value: 'totalUnpaidInvoice',
          sortable: false
        },
        {
          text: 'Unpaid Amount',
          value: 'totalUnpaidAmount',
          sortable: false
        },
        {
          text: 'Paid Amount',
          align: 'right',
          value: 'totalPaidAmount',
          sortable: false
        },
        {
          text: 'Deleted Amount',
          align: 'right',
          value: 'totalDeletedAmount',
          sortable: false
        },
        {
          text: 'Refund Amount',
          align: 'right',
          value: 'totalRefundAmount',
          sortable: false
        }
      ],
      actions: {
        load: 'statement/load',
        pdfURL: '/download-statement',
        nameOfPDF: 'Rabbi-IT-Firm-Statement-Report',
      },
      showComponent: {
        isUser: false,
        isStatus: false,
        isMethod: false,
        isEmail: false,
        isSite: false,
        isPDF: true,
        isDate: true
      }
    }
  }
}
</script>

<style scoped>

</style>